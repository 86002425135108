import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap";
import {
  getUom,
  addUom,
  updateUom,
} from "../../../common/services/markets/uomService";
import { getUomTypes } from "../../../common/services/markets/uomTypeService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import UomHistory from "./uomHistory";
import { withTranslation } from "react-i18next";

class Uom extends Form {
  state = {
    data: {
      uomId: "",
      name: "",
      uomTypeId: "",
      uomTypeName: "",
      code: "",
      conversion: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    uomTypes: [],
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    uomId: Joi.string(),
    name: Joi.string().required().label(this.props.t("UomName")),
    uomTypeId: Joi.string().required().label(this.props.t("UomType")),
    uomTypeName: Joi.string().label(this.props.t("UomTypeName")),
    code: Joi.string().label(this.props.t("UomCode")),
    conversion: Joi.number().label(this.props.t("UomConversion")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { uomId, isNew } = this.props;
    this.setState({ isNew: isNew });
    var uom = {};

    if (!isNew) {
      uom = await getUom(uomId);
      uom.eventId = uuid();
    } //New User Add
    else {
      uom = {
        uomId: uomId,
        name: "",
        uomTypeId: "",
        code: "",
        conversion: "",
        active: true,
        eventId: uuid(),
      };
    }
    this.setState({ data: this.mapToViewModel(uom), isLoading: false });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(uom.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(uom.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });

    this.setState({ uomTypes: (await getUomTypes()).filter(x => x.active || x.uomTypeId === uom.uomTypeId) });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(uom) {
    return {
      uomId: uom.uomId,
      name: uom.name,
      uomTypeId: uom.uomTypeId,
      uomTypeName: uom.uomTypeName,
      code: uom.code,
      conversion: uom.conversion,
      active: uom.active,
      eventId: uom.eventId,
    };
  }

  // async componentDidUpdate(_, prevState) {
  //   console.log(this.state.data);
  // }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });
      let response = {};

      if (!isNew) {
        response = await updateUom(this.state.data);
        toast.success(t("UomUpdated"));
      } else {
        response = await addUom(this.state.data);
        this.setState({ isNew: false });
        onRename(this.state.data.uomId, this.state.data.name);
        toast.success(t("UomAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(response);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;              
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Name = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const { isLoading, isNew, createdDate, updatedDate } = this.state;

    if (isLoading) return <Loading />;

    const { t, uomId, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <form
          
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderInput("name", t("UomName"))}
              {this.renderSelect(
                "uomTypeId",
                t("UomType"),
                this.state.uomTypes,
                "uomTypeId"
              )}
              {this.renderInput("code", t("UomCode"))}
              {this.renderInput("conversion", t("UomConversion"))}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="uom-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("History")}>
                <UomHistory uomId={uomId} />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["markets"])(Uom);
