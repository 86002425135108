import React from 'react';
import { useTranslation} from 'react-i18next';
import MarketData from "../../config/images/marketdata.jpeg";
import Environmental from "../../config/images/environmental.webp";
import Electricity from "../../config/images/Electricity.png";
import { Link } from "react-router-dom";

const Home = () => {
 const {t} = useTranslation();

 return (
   <div  className="container-fluid" style={{height:"100%"}}>
      <div style={{height:"10%", width:"100%"}} className="row">{/* <h1>{t("Homepage")}</h1> */}
        <div className="col-md-12 d-flex align-items-center justify-content-center"><h1>{t("Homepage")}</h1></div>
      </div>
      <div  style={{height:"70%"}} className="row d-flex align-items-center justify-content-center">
      <Link className="col-md-4 align-items-center justify-content-center clickable" style={{height:"60%", color:"inherit", textDecoration:"inherit"}} to="/marketdata">          
          <div style={
              {
                  height:"90%"
                , backgroundImage: "url(" + MarketData + ")"
                , backgroundPosition: 'center'
                , backgroundSize: 'cover'
                , backgroundRepeat: 'no-repeat'                
              }
            } className="tile"></div>
          <div style={{height:"10%"}} className="d-flex align-items-center justify-content-center"><h3>{t("MarketData")}</h3></div>          
        </Link>
        <Link className="col-md-4 align-items-center justify-content-center clickable" style={{height:"60%", color:"inherit", textDecoration:"inherit"}} to="/dashboards/albertaenvironmental">          
        <div style={
              {
                  height:"90%"
                , backgroundImage: "url(" + Environmental + ")"
                , backgroundPosition: 'center'
                , backgroundSize: 'cover'
                , backgroundRepeat: 'no-repeat'                
              }
            } className="tile"></div>
          <div style={{height:"10%"}} className="d-flex align-items-center justify-content-center"><h3>{t("AlbertaEnvironmental")}</h3></div>                             
        </Link>
        <Link className="col-md-4 align-items-center justify-content-center clickable" style={{height:"60%", color:"inherit", textDecoration:"inherit"}} to="/dashboards/albertaelectricity">          
          <div style={
                {
                    height:"90%"
                  , backgroundImage: "url(" + Electricity + ")"
                  , backgroundPosition: 'center'
                  , backgroundSize: 'cover'
                  , backgroundRepeat: 'no-repeat'                
                }
              } className="tile"></div>
            <div style={{height:"10%"}} className="d-flex align-items-center justify-content-center"><h3>{t("AlbertaElectricity")}</h3></div>          
        </Link>
        {/* <button className="btn btn-sm btn-secondary" onClick={() => toast("Notification")}>Notification</button>
        <button className="btn btn-sm btn-secondary" onClick={() => toast("Sticky Notification", {autoClose:false })}>Notification Sticky</button>
        <button className="btn btn-sm btn-primary" onClick={() => toast.info("Information")}>Info</button>
        <button className="btn btn-sm btn-success" onClick={() => toast.success("Success!")}>Succcess</button>
        <button className="btn btn-sm btn-warning" onClick={() => toast.warning("Warning!")}>Warning</button>
        <button className="btn btn-sm btn-danger" onClick={() => toast.error("Error!")}>Error</button> */}
      </div>
   </div>
 );
};

export default Home;