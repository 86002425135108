import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap";
import {
  getMarket,
  addMarket,
  updateMarket,
} from "../../../common/services/markets/marketService";
import { getProducts } from "../../../common/services/products/productService";
import { getVenues } from "../../../common/services/markets/venueService";
import { getTermSchedules } from "../../../common/services/markets/termScheduleService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import MarketHistory from "./marketHistory";
import { withTranslation } from "react-i18next";
import MarketAttributes from "./marketAttributes";

class Market extends Form {
  state = {
    data: {
      marketId: "",
      name: "",
      venueId: "",
      venueName: "",
      productId: "",
      productName: "",
      termScheduleId: "",
      termScheduleName: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    products: [],
    venues: [],
    termSchedules: [],
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    marketId: Joi.string(),
    name: Joi.string().required().label(this.props.t("MarketName")),
    venueId: Joi.string().required().label(this.props.t("Venue")),
    venueName: Joi.string().label(this.props.t("VenueName")),
    productId: Joi.string().required().label(this.props.t("Product")),
    productName: Joi.string().label(this.props.t("ProductName")),
    termScheduleId: Joi.string().required().label(this.props.t("TermSchedule")),
    termScheduleName: Joi.string().label(this.props.t("TermScheduleName")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { marketId, isNew } = this.props;
    this.setState({ isNew: isNew });
    var market = {};

    if (!isNew) {
      market = await getMarket(marketId);
      market.eventId = uuid();
    } //New User Add
    else {
      market = {
        marketId: marketId,
        name: "",
        venueId: "",
        productId: "",
        termScheduleId: "",
        active: true,
        eventId: uuid(),
      };
    }
    this.setState({ data: this.mapToViewModel(market), isLoading: false });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(market.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(market.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });

    this.setState({ venues: (await getVenues()).filter(x => x.active || x.venueId === market.venueId) });
    this.setState({ products: (await getProducts()).filter(x => x.active || x.productId === market.productId) });
    this.setState({ termSchedules: (await getTermSchedules()).filter(x => x.active || x.termScheduleId === market.termScheduleId) });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(market) {
    return {
      marketId: market.marketId,
      name: market.name.toString(),
      venueId: market.venueId,
      venueName: market.venueName,
      productId: market.productId,
      productName: market.productName,
      termScheduleId: market.termScheduleId,
      termScheduleName: market.termScheduleName,
      active: market.active,
      eventId: market.eventId,
    };
  }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });
      let response = {};

      if (!isNew) {
        response = await updateMarket(this.state.data);
        toast.success(t("MarketUpdated"));
      } else {
        response = await addMarket(this.state.data);
        this.setState({ isNew: false });
        onRename(this.state.data.marketId, this.state.data.name);
        toast.success(t("MarketAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(response);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;              
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Name = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const { isLoading, isNew, createdDate, updatedDate } = this.state;

    if (isLoading) return <Loading />;

    const { t, marketId, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <form
          
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderInput("name", t("MarketName"))}
              {this.renderSelect(
                "venueId",
                t("Venue"),
                this.state.venues,
                "venueId"
              )}
              {this.renderSelect(
                "productId",
                t("Product"),
                this.state.products,
                "productId"
              )}
              {this.renderSelect(
                "termScheduleId",
                t("TermSchedule"),
                this.state.termSchedules,
                "termScheduleId"
              )}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="useraccount-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("MarketAttributes")}>
                <MarketAttributes allowEdit={allowEdit} marketId={marketId} />
              </Tab>
              <Tab eventKey={2} title={t("History")}>
                <MarketHistory marketId={marketId} />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["markets"])(Market);
