import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API+"externaldatasource/";

export async function get() {
  const {data: ExternalDataSource} = await http.get(apiEndpoint);
  return ExternalDataSource;
}

export async function getExternalDataSourcesByMarketId(marketId) {
  const {data: ExternalDataSource} = await http.get(apiEndpoint+"ByMarket/"+marketId);
  return ExternalDataSource;
}

export async function getMarketsWithExternalDataSources() {
  const {data: Markets} = await http.get(apiEndpoint+"MarketsWithExternalDataSources");
  return Markets;
}
