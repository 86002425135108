import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import {
  addMarketAttribute,
  updateMarketAttribute,
} from "../../../common/services/markets/marketAttributeService";
import { getPriceBases } from "../../../common/services/markets/priceBasisService";
import { getCurrencies } from "../../../common/services/markets/currencyService";
import { getUoms } from "../../../common/services/markets/uomService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import { withTranslation } from "react-i18next";

class MarketAttributeInput extends Form {
  state = {
    data: {
      marketId: null,
      priceBasisId: null,
      currencyId: null,
      uomId: null,
      active: null,
      pricePrecision: null,
      volumePrecision: null,
      eventId: null,
    },
    createdDate: null,
    updatedDate: null,
    errors: {},
    priceBases: [],
    currencies: [],
    uoms: [],
    isLoading: true,
    isNew: null,
    collapseOpen: false,
    joiAllowUnknown: true,
  };

  schema = {
    marketId: Joi.string().required(),
    priceBasisId: Joi.string().required(),
    currencyId: Joi.string().required(),
    uomId: Joi.string().required(),
    pricePrecision: Joi.number()
      .required()
      .label(this.props.t("PricePrecision")),
    volumePrecision: Joi.number()
      .required()
      .label(this.props.t("VolumePrecision")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string().required(),
  };

  async componentDidMount() {
    //try{
    const { marketId, isNew } = this.props;

    this.setState({ isNew: isNew });
    var marketAttribute = {};

    if (!isNew) {
      const {
        marketId,
        priceBasisId,
        currencyId,
        uomId,
        pricePrecision,
        volumePrecision,
        active,
      } = this.props;
      marketAttribute.marketId = marketId;
      marketAttribute.priceBasisId = priceBasisId;
      marketAttribute.currencyId = currencyId;
      marketAttribute.uomId = uomId;
      marketAttribute.pricePrecision = pricePrecision;
      marketAttribute.volumePrecision = volumePrecision;
      marketAttribute.active = active;
      marketAttribute.eventId = uuid();
    } //New User Add
    else {
      marketAttribute = {
        marketId: marketId,
        priceBasisId: "",
        currencyId: "",
        uomId: "",
        pricePrecision: "",
        volumePrecision: "",
        active: true,
        eventId: uuid(),
      };
    }

    this.setState({
      data: this.mapToViewModel(marketAttribute),
      isLoading: false,
    });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(marketAttribute.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(marketAttribute.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });

    this.setState({
      data: this.mapToViewModel(marketAttribute),
      isLoading: false,
    });

    this.setState({ priceBases: await getPriceBases() });
    this.setState({ currencies: await getCurrencies() });
    this.setState({ uoms: await getUoms() });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(marketAttribute) {
    const { marketId } = this.props;

    return {
      marketId: marketId,
      priceBasisId: marketAttribute.priceBasisId,
      currencyId: marketAttribute.currencyId,
      uomId: marketAttribute.uomId,
      pricePrecision: marketAttribute.pricePrecision,
      volumePrecision: marketAttribute.volumePrecision,
      active: marketAttribute.active,
      eventId: marketAttribute.eventId,
    };
  }

  doSubmit = async () => {
    const { t } = this.props;
    const { isNew } = this.state;
    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });

      var response;
      if (!isNew) {
        response = await updateMarketAttribute(this.state.data);
        toast.success(t("MarketAttributeUpdated"));
      } else {
        response = await addMarketAttribute(this.state.data);
        this.setState({ isNew: false });
        toast.success(t("MarketAttributeAdded"));
      }

      // //Call back to the cell list to allow it to close the modal and update it's display value.
      this.props.submitCallbackFunction(response);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;              
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Price = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const { isLoading, createdDate, updatedDate, isNew } = this.state;

    if (isLoading) return <Loading />;

    const { t, allowEdit } = this.props;

    return (
      <div>
        <form
          
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              <div className="row">
                {this.renderSwitch("active", t("Active"))}
                <div className="col">
                  {isNew
                  ? this.renderSelect(
                      "priceBasisId",
                      t("PriceBasis"),
                      this.state.priceBases,
                      "priceBasisId",
                      null
                    )
                  : this.renderDisabledSelect(
                      "priceBasisId",
                      t("PriceBasis"),
                      this.state.priceBases,
                      "priceBasisId",
                      null
                    )}                  
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderSelect(
                    "currencyId",
                    t("Currency"),
                    this.state.currencies,
                    "currencyId",
                    null
                  )}
                </div>
                <div className="col">
                  {this.renderSelect(
                    "uomId",
                    t("Uom"),
                    this.state.uoms,
                    "uomId"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderInput("pricePrecision", t("Precision"))}
                </div>
                <div className="col">
                  {this.renderInput("volumePrecision", t("VolumePrecision"))}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default withTranslation(["markets"])(MarketAttributeInput);
