import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getProductHistory } from "../../../common/services/products/productService";
import { withTranslation } from "react-i18next";

class ProductHistory extends Component {
  state = {
    productHistory: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { productId } = this.props;
    const productHistory = this.mapToViewModel(
      await getProductHistory(productId)
    );

    this.setState({
      productHistory: _.orderBy(productHistory, "id", "desc"),
      isLoading: false,
    });
  }

  mapToViewModel(products) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return products.map((product) => ({
      id: product.id,
      name: product.name,
      shortName: product.shortName,
      commodity: product.commodityName,
      active: product.active.toString(),
      updatedByUserAccountId: product.createdByUserAccountId,
      updatedBy:
        product.updatedByUserAccountFirstName +
        " " +
        product.updatedByUserAccountLastName,
      updatedDate: new Date(product.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      writeDate: new Date(product.writeDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      eventId: product.eventId,
    }));
  }

  columns = [
    { path: "id", label: this.props.t("RecordID") },
    { path: "name", label: this.props.t("ProductName") },
    { path: "shortName", label: this.props.t("ProductShortName") },
    { path: "commodity", label: this.props.t("Commodity") },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { productHistory, sortColumn, isLoading } = this.state;

    if (isLoading) return <Loading />;

    const { t, productId } = this.props;

    return (
      <div className="history-table-container">
        {t("ProductID")} {productId}
        <Table
          className="table-extra-small"
          columns={this.columns}
          sortColumn={sortColumn}
          data={productHistory}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["products"])(ProductHistory);
