import React, { Component } from "react";
import Loading from "../../../common/components/loading/loading";
import { getMarketAttributes } from "../../../common/services/markets/marketAttributeService";
import MarketAttributesTable from "./marketAttributesTable";
import MarketAttributeInput from "./marketAttributeInput";
import { withTranslation } from "react-i18next";
import uuid from "react-uuid";
import Modal from "react-bootstrap/Modal";

class MarketAttributes extends Component {
  state = {
    marketAttributes: [],
    marketAttribute: {},
    showModal: false, // Set the initial state to show the modal
  };

  async componentDidMount() {
    this.setState({
      marketId: this.props.martketId,
      isLoading: false,
    });

    await this.loadMarketAttributes();
  }

  async loadMarketAttributes() {
    const { marketId } = this.props;

    const marketAttributes = await getMarketAttributes(marketId);

    this.setState({ marketAttributes: marketAttributes });
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleUpdate = (marketAttribute) => {
    var marketAttributes = [...this.state.marketAttributes];
    const i = marketAttributes.findIndex(
      (_marketAttribute) =>
        _marketAttribute.marketId === marketAttribute.marketId &&
        _marketAttribute.priceBasisId === marketAttribute.priceBasisId
    );
    if (i > -1) marketAttributes[i] = marketAttribute;
    else marketAttributes.push(marketAttribute);

    this.setState({ showModal: false, marketAttributes: marketAttributes });
  };

  handleAttributeSelected = (marketAttribute, isNew = false) => {
    this.setState({
      marketAttribute: marketAttribute,
      isNew: isNew,
      showModal: true,
    });
    //this.setState({price: price, bid: bid, offer: offer, showModal: false})
  };

  render() {
    const {
      showModal,
      marketAttributes,
      marketAttribute,
      isLoading,
      priceBasisId,
      currencyId,
      uomId,
      pricePrecision,
      volumePrecision,
      currencySymbol,
      isNew,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, allowEdit, marketId } = this.props;

    const newMarketAttribute = {
      marketId: marketId,
      active: true,
      eventId: uuid(),
    };

    return (
      <div>
        <div className="row">
          <div className="col-md-3 flex-column d-flex">
          { allowEdit &&
            <button
              onClick={() =>
                this.handleAttributeSelected(newMarketAttribute, true)
              }
              className="btn btn-sm btn-success float-sm-right"
              style={{ marginTop: "auto" }}
            >
              {t("AddAttribute")}
            </button>
          }
          </div>
          <div className="col-md-8"></div>
          <Modal
            show={showModal}
            onHide={this.handleClose}
            //centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header
              className="bg-dark text-light"
              
              closeButton
            >
              <Modal.Title>
                {isNew ? t("AddAttribute") : t("UpdateAttribute")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-light">
              <MarketAttributeInput
                MarketAttribute={newMarketAttribute.MarketAttribute}
                marketId={marketId}
                priceBasisId={marketAttribute.priceBasisId}
                currencyId={marketAttribute.currencyId}
                uomId={marketAttribute.uomId}
                active={marketAttribute.active}
                //qualityAttributeValues={qualityAttributeValues}
                marketAttributes={marketAttributes}
                allowEdit={allowEdit}
                // onSave={this.handleClose}
                isNew={isNew} //Stub
                pricePrecision={marketAttribute.pricePrecision}
                volumePrecision={marketAttribute.volumePrecision}
                submitCallbackFunction={this.handleUpdate}
              />
            </Modal.Body>
          </Modal>
          <div className="col">
            <MarketAttributesTable
              onRowClick={this.handleAttributeSelected}
              marketAttributes={marketAttributes}
              marketId={marketId}
              priceBasisId={priceBasisId}
              currencyId={currencyId}
              uomId={uomId}
              pricePrecision={pricePrecision}
              volumePrecision={volumePrecision}
              currencySymbol={currencySymbol}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["markets"])(MarketAttributes);
