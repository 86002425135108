import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getPriceBasisHistory } from "../../../common/services/markets/priceBasisService";
import { withTranslation } from "react-i18next";

class PriceBasisHistory extends Component {
  state = {
    priceBasisHistory: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { priceBasisId } = this.props;
    const priceBasisHistory = this.mapToViewModel(
      await getPriceBasisHistory(priceBasisId)
    );

    this.setState({
      priceBasisHistory: _.orderBy(priceBasisHistory, "id", "desc"),
      isLoading: false,
    });
  }

  mapToViewModel(priceBases) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return priceBases.map((priceBasis) => ({
      id: priceBasis.id,
      name: priceBasis.name,
      active: priceBasis.active.toString(),
      updatedByUserAccountId: priceBasis.createdByUserAccountId,
      updatedBy:
        priceBasis.updatedByUserAccountFirstName +
        " " +
        priceBasis.updatedByUserAccountLastName,
      updatedDate: new Date(priceBasis.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      writeDate: new Date(priceBasis.writeDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      eventId: priceBasis.eventId,
    }));
  }

  columns = [
    { path: "id", label: this.props.t("RecordID") },
    { path: "name", label: this.props.t("PriceBasisName") },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { priceBasisHistory, sortColumn, isLoading } = this.state;

    if (isLoading) return <Loading />;

    const { t, priceBasisId } = this.props;

    return (
      <div className="history-table-container">
        {t("PriceBasisID")} {priceBasisId}
        <Table
          className="table-extra-small"
          columns={this.columns}
          sortColumn={sortColumn}
          data={priceBasisHistory}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(PriceBasisHistory);
