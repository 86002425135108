import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getCommodityHistory } from "../../../common/services/products/commodityService";
import { withTranslation } from "react-i18next";

class CommodityHistory extends Component {
  state = {
    commodityHistory: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { commodityId } = this.props;
    const commodityHistory = this.mapToViewModel(
      await getCommodityHistory(commodityId)
    );

    this.setState({
      commodityHistory: _.orderBy(commodityHistory, "id", "desc"),
      isLoading: false,
    });
  }

  mapToViewModel(commoditys) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return commoditys.map((commodity) => ({
      id: commodity.id,
      name: commodity.name,
      description: commodity.description,
      commodityClass: commodity.commodityClassName,
      active: commodity.active.toString(),
      updatedByUserAccountId: commodity.createdByUserAccountId,
      updatedBy:
        commodity.updatedByUserAccountFirstName +
        " " +
        commodity.updatedByUserAccountLastName,
      updatedDate: new Date(commodity.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      writeDate: new Date(commodity.writeDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      eventId: commodity.eventId,
    }));
  }

  columns = [
    { path: "id", label: this.props.t("RecordID") },
    { path: "name", label: this.props.t("CommodityName") },
    { path: "description", label: this.props.t("CommodityDescription") },
    { path: "commodityClass", label: this.props.t("CommodityClass") },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { commodityHistory, sortColumn, isLoading } = this.state;

    if (isLoading) return <Loading />;

    const { t, commodityId } = this.props;

    return (
      <div className="history-table-container">
        {t("CommodityID")} {commodityId}
        <Table
          className="table-extra-small"
          columns={this.columns}
          sortColumn={sortColumn}
          data={commodityHistory}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["products"])(CommodityHistory);
