import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import Loading from "../../common/components/loading/loading";
import Pagination, {
  paginate,
} from "../../common/components/table/pagination";
import { getTrades } from "../../common/services/albertaEnvironmental/albertaEnvironmentalService";
import SearchBox from "../../common/components/search/searchBox";
import { formatDate } from "../../common/services/utilities";
import { withTranslation } from "react-i18next";
import AlbertaEnvironmentalTradesTable from "./albertaEnvironmentalTradesTable";
import Input from "../../common/components/form/input";
import Select from "../../common/components/form/select";
import * as XLSX from 'xlsx';
import FontAwesome from "react-fontawesome";
import TradesChart from './tradesChart';

class AlbertaEnvironmentalTrades extends Component {

  state = {
    trades: [],
    pageSize: 20,
    searchQuery: "",
    currentPage: 1,
    aeor: true,
    epc: true,
    startDate: null,
    endDate: null,
    minDate: null,
    maxDate: null,
    quantificationProtocols: [],
    quantificationProtocol: "All",
    sortColumn: { path: "TransactionId", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    //this.setState({ trades: await getTrades(), isLoading: false });
    const trades = (await getTrades()).filter(x => x.TransactionId !== 0);
    
    var date = new Date();
    const endDate= date.toLocaleDateString('fr-CA');
    const maxDate= date.toLocaleDateString('fr-CA');
    const startDate= (new Date(new Date().setDate(date.getDate() - 30))).toLocaleDateString('fr-CA');;
    const minDate=(new Date(Math.min.apply(null, trades.map(x => new Date(x.TransactionDate))))).toLocaleDateString('fr-CA');

    const quantificationProtocols = [{quantificationProtocol: "All"}].concat([ ...new Set(trades.filter(x => x.QuantificationProtocol).map(x => x.QuantificationProtocol))].sort().map(x => ({quantificationProtocol: x})));
    //quantificationProtocols.push({quantificationProtocol: "All"});
    this.setState({startDate: startDate, endDate: endDate, minDate: minDate, maxDate: maxDate, trades: trades, isLoading: false, quantificationProtocols: quantificationProtocols});
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleStartDateChange = (startDate) => {
    if(!isNaN(Date.parse(startDate)))
    {
      this.setState({ startDate: startDate, currentPage: 1  });
    }
  };

  handleEndDateChange = (endDate) => {
    if(!isNaN(Date.parse(endDate)))
    {
      this.setState({ endDate: endDate, currentPage: 1  });
    }
  };
  handleQuantificationProtocolChange = (quantificationProtocol) => {
    this.setState({ quantificationProtocol: quantificationProtocol, currentPage: 1  });
  };

  handleToggleAeor = () => {
    this.setState({ aeor: !this.state.aeor, currentPage: 1  });
  };

  handleToggleEpc = () => {
    this.setState({ epc: !this.state.epc, currentPage: 1  });
  };

  downloadExcel = (data) => {
    const excelData = data.map((trade) => (
      {
          TransactionId: trade.TransactionId,
          TransactionDate: formatDate(trade.TransactionDate),
          Buyer: trade.Buyer,
          Seller: trade.Seller,
          Quantity: trade.Quantity,
          Registry: trade.Registry,
          Vintage: trade.Vintage,
          QuantificationProtocol: trade.QuantificationProtocol,
          SerialStart: trade.SerialStart,
          SerialEnd: trade.SerialEnd,
      }
  ));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "AlbertaEnvironmentalTrades.xlsx");
  };

  getFilteredData = () => {
    const {
      searchQuery,
      sortColumn,
      epc,
      aeor,
      startDate,
      endDate,
      quantificationProtocol,
      trades: allTrades,
    } = this.state;

    let filtered = allTrades;

    if (!epc) filtered = filtered.filter((u) => u.Registry !== "Emission Performance Credit");
    if (!aeor) filtered = filtered.filter((u) => u.Registry !== "AEOR");

    filtered = filtered.filter(
      (u) =>
        new Date(u.TransactionDate) >= new Date(startDate+"T00:00:00")
          && new Date(u.TransactionDate) <= new Date(endDate+"T00:00:00")
    );

    if(quantificationProtocol !== "All")
    {
      filtered = filtered.filter(
        (u) => u.QuantificationProtocol === quantificationProtocol ?? u.QuantificationProtocol
      )
    }

    if (searchQuery)
      filtered = filtered.filter(
        (u) =>
          (
            u.Buyer !== null &&          
            u.Buyer.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          (
            u.Seller !== null &&
            u.Seller.toLowerCase().includes(searchQuery.toLowerCase())
          )
          ||
          (
            u.SerialStart !== null &&
            u.SerialStart.split("-", 2).join("-").toLowerCase().includes(searchQuery.toLowerCase())
          )          
      );

      const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

      return { data: sorted };
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
    } = this.state;

    const {data} = this.getFilteredData();
    const trades = paginate(data, currentPage, pageSize);

    return { totalCount: data.length, data: trades, allData: data };
  };

  render() {
    const {
      pageSize,
      searchQuery,
      currentPage,
      sortColumn,
      aeor,
      epc,
      startDate,
      endDate,
      minDate,
      maxDate,
      quantificationProtocols,
      quantificationProtocol,
      isLoading,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, isSubscriber } = this.props;

    const { totalCount, data: trades, allData: allTrades } = this.getPagedData();

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

    return (
      <div className="container-fluid">
        <div className="row">
        <div className="col-md-12 d-flex align-items-center justify-content-center"><h1>{t("AlbertaEnvironmentalTrades")}</h1></div>
          <div className="col-md-3" style={{alignSelf:"flex-end"}}>
            <SearchBox 
              value={searchQuery} 
              onChange={this.handleSearch} 
              disabled = {!isSubscriber}
            />
          </div>
          <div className="col-md-2">
            <Input                    
                 
                type="date"
                min={minDate} 
                max={endDate} 
                onKeyDown={(e) => e.preventDefault()}
                onFocus={(e) => e.blur()}                
                onChange={e => this.handleStartDateChange(e.currentTarget.value)}
                //name = "name"
                value = {startDate}
                label = {t("StartDate")}
                disabled = {!isSubscriber}
            />                
          </div>
          <div className="col-md-2">
            <Input                    
                 
                type="date"
                min={startDate} 
                max={maxDate} 
                onKeyDown={(e) => e.preventDefault()}
                onFocus={(e) => e.blur()}                
                onChange={e => this.handleEndDateChange(e.currentTarget.value)}
                //name = "name"
                value = {endDate}
                label = {t("EndDate")}
                disabled = {!isSubscriber}                
            />                
          </div>          
          <div className="col-md-1" style={{alignSelf:"flex-end"}}>
            <Form.Switch
              className="switch"
              type="switch"
              label={t("Aeor")}
              checked={aeor}
              value={aeor}
              onChange={this.handleToggleAeor}
              disabled = {!isSubscriber}
            />
          </div>
          <div className="col-md-1" style={{alignSelf:"flex-end"}}>
            <Form.Switch
              className="switch"
              type="switch"
              label={t("Epc")}
              checked={epc}
              value={epc}
              onChange={this.handleToggleEpc}
              disabled = {!isSubscriber}
            />
          </div>       
          <div className='col-md-2'>
            <Select
                 
                idField = "quantificationProtocol"
                optionField = "quantificationProtocol"
                name = "quantificationProtocol"
                //disabled={Object.keys(organizations).length < 2}
                value = {quantificationProtocol}
                label = {t("QuantificationProtocol")}
                options={quantificationProtocols}
                onChange={e => this.handleQuantificationProtocolChange(e.currentTarget.value)}
                disabled = {!isSubscriber}
            />        
          </div>   
          <div className='col-md-1'  style={{alignSelf:"flex-end"}}>    
            <button 
              className="btn btn-sm btn-secondary" 
              onClick={()=>this.downloadExcel(allTrades)}
              disabled = {!isSubscriber}
            >
              <FontAwesome  name="download"/>
              {" "}
              {t("Excel")}
            </button>
          </div>           
        </div>
        <div className="row">
          <div className="col-md-2">
            <p>{t("RetrievedAlbertaEnvironmentalTrades", { count: totalCount })}</p>
          </div>
          {!isSubscriber &&
            <div className="col-md-8 d-flex align-items-center justify-content-center" style={{fontStyle: "italic", color: "darkred"}}>
              <p>{t("SubscriptionInquiry")}{" "}<a href={"mailto:"+contactEmail}>{contactEmail}</a></p>
            </div>        
          }
        </div>
        <div className="col-md-12 tile">
            <TradesChart 
                data={allTrades} 
                title="Trade Quantities by Day" 
                startDate={startDate}
                endDate={endDate}
                // uniqueStatusValues={uniqueEPCStatusValues} 
                // colorScheme={EPCColorScheme} 
                // vintages={EPCVintages}                
            />
            </div>              
        <AlbertaEnvironmentalTradesTable
          trades={trades}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          //onRowClick={this.handleTradeSelected}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(AlbertaEnvironmentalTrades);
