import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap";
import {
  getUserAccountSubscription,
  addUserAccountSubscription,
  updateUserAccountSubscription,
} from "../../../common/services/marketdata/userAccountSubscriptionService";
import { getUserAccountsByOrganization } from "../../../common/services/auth/userAccountOrganizationService";
import {
  getOrganizationSubscription,
  getOrganizationSubscriptions,
} from "../../../common/services/marketdata/organizationSubscriptionService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import UserAccountSubscriptionHistory from "./userAccountSubscriptionHistory";
import { withTranslation } from "react-i18next";

class UserAccountSubscription extends Form {
  state = {
    data: {
      userAccountSubscriptionId: "",
      userAccountId: "",
      organizationSubscriptionId: "",
      contributor: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    organizationSubscriptions: [],
    userAccounts: [],
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    userAccountSubscriptionId: Joi.string(),
    userAccountId: Joi.string().required().label(this.props.t("UserAccount")),
    organizationSubscriptionId: Joi.string()
      .required()
      .label(this.props.t("OrganizationSubscription")),
    contributor: Joi.boolean().label(this.props.t("Contributor")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { userAccountSubscriptionId, isNew } = this.props;
    this.setState({ isNew: isNew });
    var userAccountSubscription = {};

    if (!isNew) {
      userAccountSubscription = await getUserAccountSubscription(
        userAccountSubscriptionId
      );
      userAccountSubscription.eventId = uuid();
    } //New UserAccount Market Subscription Add
    else {
      userAccountSubscription = {
        userAccountSubscriptionId: userAccountSubscriptionId,
        userAccountId: "",
        organizationSubscriptionId: "",
        contributor: false,
        active: true,
        eventId: uuid(),
      };
    }
    this.setState({
      data: this.mapToViewModel(userAccountSubscription),
      isLoading: false,
    });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(
        userAccountSubscription.createdDate
      ).toLocaleDateString("en-US", dateFormatOptions),
      updatedDate: new Date(
        userAccountSubscription.updatedDate
      ).toLocaleDateString("en-US", dateFormatOptions),
    });

    this.setState({
      organizationSubscriptions: (await getOrganizationSubscriptions()).filter(
        (x) =>
          x.active ||
          x.organizationSubscriptionId ===
            userAccountSubscription.organizationSubscriptionId
      ),
    });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  async componentDidUpdate(_, prevState) {
    const { organizationSubscriptionId } = this.state.data;

    if (
      organizationSubscriptionId !== prevState.data.organizationSubscriptionId
    ) {
      this.updateUsers(organizationSubscriptionId);
    }
  }

  async updateUsers(organizationSubscriptionId) {
    const organizationSubscription = await getOrganizationSubscription(
      organizationSubscriptionId
    );
    const userAccounts = (
      await getUserAccountsByOrganization(
        organizationSubscription.organizationId
      )
    ).filter((x) => x.active);

    this.setState({ userAccounts: userAccounts });
  }

  mapToViewModel(userAccountSubscription) {
    return {
      userAccountSubscriptionId:
        userAccountSubscription.userAccountSubscriptionId,
      userAccountId: userAccountSubscription.userAccountId,
      organizationSubscriptionId:
        userAccountSubscription.organizationSubscriptionId,
      contributor: userAccountSubscription.contributor,
      active: userAccountSubscription.active,
      eventId: userAccountSubscription.eventId,
    };
  }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });
      let response = {};

      if (!isNew) {
        response = await updateUserAccountSubscription(this.state.data);

        toast.success(t("UserAccountSubscriptionUpdated"));
      } else {
        response = await addUserAccountSubscription(this.state.data);

        this.setState({ isNew: false });

        const { organizationSubscriptions, userAccounts } = this.state;
        const userAccount = {
          ...userAccounts.find(
            (x) => x.userAccountId === this.state.data.userAccountId
          ),
        };
        const organizationSubscription = {
          ...organizationSubscriptions.find(
            (x) =>
              x.organizationSubscriptionId ===
              this.state.data.organizationSubscriptionId
          ),
        };

        onRename(
          this.state.data.userAccountSubscriptionId,
          userAccount.login + " | " + organizationSubscription.name
        );

        toast.success(t("UserAccountSubscriptionAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(response);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;              
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Login = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const {
      isLoading,
      isNew,
      createdDate,
      updatedDate,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, userAccountSubscriptionId, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <form
          
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderSwitch("contributor", t("Contributor"))}
              {isNew
                ? this.renderSelect(
                    "organizationSubscriptionId",
                    t("OrganizationSubscription"),
                    this.state.organizationSubscriptions,
                    "organizationSubscriptionId",
                    "name"
                  )
                : this.renderDisabledSelect(
                    "organizationSubscriptionId",
                    t("OrganizationSubscription"),
                    this.state.organizationSubscriptions,
                    "organizationSubscriptionId",
                    "name"
                  )}
              {isNew
                ? this.renderSelect(
                    "userAccountId",
                    t("UserAccount"),
                    this.state.userAccounts,
                    "userAccountId",
                    "login"
                  )
                : this.renderDisabledSelect(
                    "userAccountId",
                    t("UserAccount"),
                    this.state.userAccounts,
                    "userAccountId",
                    "login"
                  )}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="useraccount-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("History")}>
                <UserAccountSubscriptionHistory
                  userAccountSubscriptionId={userAccountSubscriptionId}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["auth"])(UserAccountSubscription);
