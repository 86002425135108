import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API+"externaldatasourceurl/";

export async function get() {
  const {data: ExternalDataSourceUrls} = await http.get(apiEndpoint);
  return ExternalDataSourceUrls;
}

export async function getUrlsByExternalDataSourceId(externalDataSourceId) {
  const {data: ExternalDataSourceUrls} = await http.get(apiEndpoint+"UrlsByExternalDataSource/"+externalDataSourceId);
  return ExternalDataSourceUrls;
}
