import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API+"aeso/";

export async function getWindLongTermForecastByMarketDate(marketDate) {
    const {data: windLongTermForecast} = await http.get(apiEndpoint+"AesoWindLongTermForecast/"+marketDate);
    return windLongTermForecast;
}

export async function getSolarLongTermForecastByMarketDate(marketDate) {
    const {data: solarLongTermForecast} = await http.get(apiEndpoint+"AesoSolarLongTermForecast/"+marketDate);
    return solarLongTermForecast;
}

export async function getPoolPriceByMarketDate(fromDate, toDate) {
    const {data: poolPrice} = await http.get(apiEndpoint+"AesoPoolPrice/"+fromDate+"/"+toDate);
    return poolPrice;
}

export async function getInternalLoadByMarketDate(fromDate, toDate) {
    const {data: internalLoad} = await http.get(apiEndpoint+"AesoInternalLoad/"+fromDate+"/"+toDate);
    return internalLoad;
}