import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { getOrganizationsByUser} from "../../common/services/auth/userAccountOrganizationService";
import { getOrganizations} from "../../common/services/auth/organizationService";
import { getCommodities } from "./../../common/services/products/commodityService";
import { getInstruments, getOrAddInstrument } from "./../../common/services/marketdata/instrumentService";
import { formatDate } from "./../../common/services/utilities";
import { getProducts, getProductWithQualityAttributes } from "./../../common/services/products/productService";
import { getMarketsWithAttributes } from "./../../common/services/markets/marketService";
import { getTermsByDate } from "../../common/services/markets/termService";
import Form from "./../../common/components/form/form";
import { withTranslation } from "react-i18next";
import _ from "lodash";

class OrderForm extends Form {
  state = {
    data: {
      orderDirection: "",
      organizationId: "",
      commodityId: "",
      productId: "",
      marketId: "",
      termId: "",      
      priceBasisId: "",
      currencyID: "",
      uomId: "",
      price: 0,
      volume: 0,
      fillOrKill: false,
      allOrNone: false,
      orderState: "Live",
      eventId: "",
    },
    organizations: [],
    instruments: [],
    commodities: [],
    products: [],
    markets: [],
    terms: [],
    productWithQualityAttributes: {},
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    commodityId: Joi.string().required().label(this.props.t("Commodity")),
    productId: Joi.string().required().label(this.props.t("Product")),
    marketId: Joi.string().required().label(this.props.t("Market")),
    priceBasisId: Joi.string().required().label(this.props.t("PriceBasis")),
    termId: Joi.string().required().label(this.props.t("Term")),
    uomId: Joi.string().required(),
    currencyId: Joi.string().required(),
    orderDirection: Joi.string(),
    organizationId: Joi.string().required().label(this.props.t("Organization")),
    price: Joi.number().required().label(this.props.t("Price")),
    volume: Joi.number().required().label(this.props.t("Volume")),
    fillOrKill: Joi.boolean().label(this.props.t("FillOrKill")),
    allOrNone: Joi.boolean().label(this.props.t("AllOrNone")),
    orderState: Joi.string(),
    eventId: Joi.string(), 
  };

  async componentDidMount() {
    const {marketControl, tradeOnBehalf, userAccountId} = this.props;

    const marketControlMarkets = marketControl.filter(x => x.active).map(x => x.marketId);
    const markets = (await getMarketsWithAttributes()).filter(o => o.active && marketControlMarkets.includes(o.marketId));

    const marketControlProducts = markets.map(x => x.productId)
    const products = (await getProducts()).filter(o => o.active && marketControlProducts.includes(o.productId));

    const marketControlCommodities = products.map(x => x.commodityId)
    const commodities = (await getCommodities()).filter(o => o.active && marketControlCommodities.includes(o.commodityId));

    const organizations = tradeOnBehalf 
      ? (await getOrganizations()).filter(o => o.active)
      : (await getOrganizationsByUser(userAccountId)).filter(o => o.active);


    const instruments = await getInstruments();

    this.setState({ commodities: commodities, products: products, markets: markets, organizations: organizations, instruments: instruments})

    this.initializeOrderForm();
  }

  async componentDidUpdate(previousProps, previousState) {
    if (
      !_.isEqual(previousProps.data, this.props.data) ||      
      previousProps.type !== this.props.type ||
      previousProps.lastGridClick !== this.props.lastGridClick
    )
    {
      this.setState({isLoading: true});
      this.initializeOrderForm();
    }
    else
    {
      if (previousState.data.commodityId !== this.state.data.commodityId && previousState.data.productId === this.state.data.productId)
      {
        const { products } = this.getFilteredProducts();
        if(!products.includes(x => x.productId === this.state.data.productId))
        {
          let data = {...this.state.data};
          data.productId = "";
          this.setState({data: data});
        }
      }

      if (previousState.data.marketId !== this.state.data.marketId)
      {

        const marketIndex = this.state.markets.findIndex(x => x.marketId === this.state.data.marketId);
        const market = this.state.markets[marketIndex];
        const date = new Date();

        if (market)
        {
          const terms = await getTermsByDate(market.termScheduleId, formatDate(date));
          this.setState({
            terms: terms.sort((a, b) =>
              new Date(a.endDate) > new Date(b.endDate) ? 1 : -1
            ),
          });
        }

        let data = {...this.state.data};
        data.priceBasisId = "";
        data.termId = "";
        this.setState({data: data});
      }

      if (previousState.data.priceBasisId !== this.state.data.priceBasisId)
      {
        const { priceBases } = this.getFilteredPriceBases();

        const priceBasisIndex = priceBases.findIndex(x => x.priceBasisId === this.state.data.priceBasisId);    

        if (priceBasisIndex >= 0)
        {
          const { uomId, currencyId } = priceBases[priceBasisIndex];        
          let data = {...this.state.data};
          data.uomId = uomId ?? "";
          data.currencyId = currencyId ?? "";
          this.setState({data: data});
        }
      }

      if (previousState.data.productId !== this.state.data.productId)
      {    
        //Remove previous quality attributes from the Joi Schema and the data object
        let schema = { ...this.schema };
        let data = {...this.state.data};

        if (previousState.productWithQualityAttributes && previousState.productWithQualityAttributes.qualityAttributes)
        {
          previousState.productWithQualityAttributes.qualityAttributes.forEach((a) => {
            delete schema[a.qualityAttributeId];
            delete data[a.qualityAttributeId];
          });          
        }

        if (previousState.data.marketId === this.state.data.marketId)
        {
          const { markets } = this.getFilteredMarkets();
          if(!markets.includes(x => x.marketId === this.state.data.marketId))
          {         
            data.marketId = "";            
          }
        }

        this.setState({data: data});

        if (this.state.data.productId)
        {
          const product = await getProductWithQualityAttributes(this.state.data.productId);    
          this.setState({productWithQualityAttributes: product});

          //Extend the Joi schema to include new attributes
          this.schema = {
            ...schema,
            ...product.qualityAttributes
              .filter((a) => a.isPriceDriver)
              .reduce((acc, attribute) => {
                acc[attribute.qualityAttributeId] = Joi.string()
                  .required()
                  .label(attribute.qualityAttributeName);
                return acc;
              }, {})
          };      
        }
        else
        {
          this.setState({productWithQualityAttributes: {} })
        }
      }
    }
  }


  initializeOrderForm()
  {
    const { data, type } = this.props;
    const { products, markets } = this.state;
    const marketIndex = markets.findIndex(m => m.marketId === data.marketId);
    const market = markets[marketIndex];
    const productIndex = products.findIndex(x => x.productId === (market ? market.productId : ""));
    const product = products[productIndex];

    //try{
    const order = {
        commodityId: product ? product.commodityId : "",
        productId:  product ? product.productId : "",
        marketId:  data.marketId,
        termId:  data.termId,
        priceBasisId: data.priceBasisId,
        currencyId:  data.currencyId,      
        uomId:  data.uomId,   
        orderDirection: type === "Bid" ? "Sell" : "Buy",
        organizationId: this.state.organizations.length > 0 ? this.state.organizations[0].organizationId : "",
        price: type === "Bid" ? data.bidPrice ?? data.offerPrice : data.offerPrice ?? data.bidPrice,
        volume: type === "Bid" ? data.runningTotalBidVolume ?? data.runningTotalOfferVolume : data.runningTotalOfferVolume ?? data.runningTotalBidVolume,
        orderState: "Live",
        fillOrKill: false,
        allOrNone: false,
        eventId: uuid(),
        ...(data.qualityAttributeValues ?? []).reduce((acc, attribute) => {
          acc[attribute.qualityAttributeId] = attribute.qualityAttributeValueId;
          return acc;
        }, {}),        
    };

    this.setState({ data: order, instrumentId: data.instrumentId, isLoading: false });
  }

  setOrderDirection(orderDireciton)
  {
    const { data } = this.state;
    let order = { ...data };
    order.orderDirection = orderDireciton;
    this.setState({ data: order });
  }

  doSubmit = async () => {
    const { submitOrder } = this.props;
    const { instruments, productWithQualityAttributes } = this.state;

    //try {

      const { data, markets, terms } = this.state;
      const { priceBases } = this.getFilteredPriceBases()

      var instrument = {};
      const marketName = markets.filter(m => m.marketId === data.marketId)[0].name;
      const termName = terms.filter(m => m.termId === data.termId)[0].name;
      const priceBasisName = priceBases.filter(m => m.priceBasisId === data.priceBasisId)[0].priceBasisName;
      const currencySymbol = priceBases.filter(m => m.priceBasisId === data.priceBasisId)[0].currencySymbol;
      const pricePrecision = priceBases.filter(m => m.priceBasisId === data.priceBasisId)[0].pricePrecision;
      const volumePrecision = priceBases.filter(m => m.priceBasisId === data.priceBasisId)[0].volumePrecision;
      const uomName = priceBases.filter(m => m.priceBasisId === data.priceBasisId)[0].uomName;
      const currencyName = priceBases.filter(m => m.priceBasisId === data.priceBasisId)[0].currencyName;

        const qualityAttributeValues = productWithQualityAttributes.qualityAttributes
          .filter((a) => a.isPriceDriver)
          .map((attributeValue) => {
            const index = productWithQualityAttributes.qualityAttributeValues.findIndex(
              (u) =>
                u.qualityAttributeValueId ===
                data[attributeValue.qualityAttributeId]
            );
            return {
              qualityAttributeId: attributeValue.qualityAttributeId,
              qualityAttributeValueId: data[attributeValue.qualityAttributeId],
              value: productWithQualityAttributes.qualityAttributeValues[index].value,
              active: productWithQualityAttributes.qualityAttributeValues[index].active,
            };
          });

        const instrumentIndex = instruments.findIndex(i => 
          i.marketId === data.marketId &&
          i.termId === data.termId &&
          i.priceBasisId === data.priceBasisId &&
          i.currencyId === data.currencyId &&
          i.uomId === data.uomId &&
          _.isEqual(i.qualityAttributeValues.map(x => x.qualityAttributeValueId), qualityAttributeValues.map(x => x.qualityAttributeValueId))
        )

        if (instrumentIndex >= 0)
        {
          instrument = instruments[instrumentIndex];
        }
        else
        {
          instrument =
          {
            //instrumentId: uuid(),
            marketId: data.marketId,
            marketName: marketName,
            termId: data.termId,
            termName: termName,
            priceBasisId: data.priceBasisId,            
            priceBasisName: priceBasisName,
            currencyId: data.currencyId,
            currencyName: currencyName,
            uomId: data.uomId,
            uomName: uomName,
            active: true,
            eventId: data.eventId,
            qualityAttributeValues: qualityAttributeValues,
          }

          const response = await getOrAddInstrument(instrument)

          //Get the instmentId from the response and set it
          instrument.instrumentId = response.instrumentId;

          //Push the new instrment into state
          let instruments = [...this.state.instruments]
          instruments.push(instrument);
          this.setState({instruments:instruments})
        }
      //}

      const submitData = 
      {
        instrumentId: instrument.instrumentId,
        orderDirection: data.orderDirection,
        organizationId: data.organizationId,
        price: Number(data.price),
        volume: Number(data.volume),
        fillOrKill: data.fillOrKill,
        allOrNone: data.allOrNone,
        orderState: data.orderState,
        eventId: data.eventId,
        MarketId: instrument.marketId,
        MarketName: instrument.marketName,
        TermId: instrument.termId,
        TermName: instrument.termName,
        PriceBasisId: instrument.priceBasisId,
        PriceBasisName: instrument.priceBasisName,
        CurrencyId: instrument.currencyId,
        CurrencyName: instrument.currencyName,
        UomId: instrument.uomId,
        UomName: instrument.uomName,
        currencySymbol: currencySymbol,
        pricePrecision: pricePrecision,
        volumePrecision: volumePrecision,
        QualityAttributeValues: instrument.qualityAttributeValues,
      }

      submitOrder(submitData);

      //Reset the event ID so we can release another
      data.eventId = uuid();
      this.setState({ data: data });
  };

  getFilteredProducts()
  {
    const {data, products} = this.state;
    const {commodityId} = data;
    const filteredProducts = products.filter(x => x.commodityId === commodityId);
    return { products: filteredProducts };
  }

  getFilteredMarkets()
  {
    const {data, markets} = this.state;
    const {productId} = data;
    const filteredMarkets = markets.filter(x => x.productId === productId);
    return { markets: filteredMarkets };
  }

  getFilteredPriceBases()
  {
    const { data, markets } = this.state;
    const { marketId } = data;
    const marketIndex = markets.findIndex(x => x.marketId === marketId);
    const priceBases = markets[marketIndex] ? markets[marketIndex].marketAttributes : [];
    return { priceBases: priceBases };
  }

  getMarketAttributes()
  {
    const { data } = this.state;
    const { priceBases } = this.getFilteredPriceBases();
    const { priceBasisId } = data;
    const priceBasisIndex = priceBases.findIndex(x => x.priceBasisId === priceBasisId);    
    const marketAttributes = priceBases[priceBasisIndex] ?? {};

    return { marketAttributes: marketAttributes };
  }

  render() {
    const
    { 
      organizations,
      commodities,
      terms,
      productWithQualityAttributes
     } = this.state;
    
     const { products } = this.getFilteredProducts();
     const { markets } = this.getFilteredMarkets();
     const { priceBases } = this.getFilteredPriceBases();
     const { marketAttributes } = this.getMarketAttributes();

     const { marketControl } = this.props;
     const marketControlSettings = marketControl.find(x => x.marketId === this.state.data.marketId)
     const allowFillOrKill = marketControlSettings && marketControlSettings.allowFillOrKill;
     const allowAllOrNone = marketControlSettings && marketControlSettings.allowAllOrNone;

     const {
      currencyName,
      currencySymbol,
      uomName,
      pricePrecision,
      volumePrecision
    } = marketAttributes;

    const priceString = this.state.data.price && (currencySymbol??"$")+ Number(this.state.data.price).toFixed(pricePrecision ?? 2);
    const volumeString =  this.state.data.volume && Number(this.state.data.volume).toFixed(volumePrecision ?? 0);

    const { t, tradeAllowed } = this.props;

    return (
      <div className="container-fluid">
        <form
          
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={tradeAllowed ? "" : "disabled"} >
          <div className="row">
              <div className="col-sm-12">
                {
                  this.renderSelect(
                    "organizationId",
                    t("Organization"),
                    organizations,
                    "organizationId",
                    "name"
                  )
                }
              </div>
            </div>       
            <div className="row">
              <div className="col-sm-12">
                {
                  this.renderSelect(
                    "commodityId",
                    t("Commodity"),
                    commodities,
                    "commodityId",
                    "name"
                  )
                }
              </div>
            </div>   
            <div className="row">
              <div className="col-sm-12">
                {
                  this.renderSelect(
                    "productId",
                    t("Product"),
                    products,
                    "productId",
                    "name"
                  )
                }
              </div>
            </div>   
            <div className="row">
              <div className="col-sm-12">
                {
                  this.renderSelect(
                    "marketId",
                    t("Market"),
                    markets,
                    "marketId",
                    "name"
                  )
                }
              </div>
            </div>               
            <div>
              {productWithQualityAttributes.qualityAttributes &&
                productWithQualityAttributes.qualityAttributes
                  .filter((a) => a.isPriceDriver)
                  .map((attribute) => (
                    <div className="row" key={attribute.qualityAttributeId}>
                      <div className="col">
                        {this.renderSelect(
                          attribute.qualityAttributeId,
                          attribute.qualityAttributeName,
                          productWithQualityAttributes.qualityAttributeValues.filter(
                            (v) =>
                              v.qualityAttributeId ===
                              attribute.qualityAttributeId
                              && (v.active || v.qualityAttributeValueId === attribute.qualityAttributeId)
                          ),
                          "qualityAttributeValueId",
                          "value"
                        )}
                      </div>
                    </div>
                  ))}
            </div>                    
            <div className="row">
              <div className="col-sm-12">
                {
                  this.renderSelect(
                    "termId",
                    t("Term"),
                    terms,
                    "termId",
                    "name"
                  )
                }
              </div>
            </div>               
            <div className="row">
              <div className="col-sm-12">
                {
                  this.renderSelect(
                    "priceBasisId",
                    t("PriceBasis"),
                    priceBases,
                    "priceBasisId",
                    "priceBasisName"
                  )
                }
              </div>
            </div>                                                               
            <div className="row">
              <div className="col-sm-6">
                {this.renderNumericInput("price", t("Price")+(currencyName ? " ("+currencyName+")" : ""), pricePrecision, "number")}
              </div>
              <div className="col-sm-6">
                {this.renderNumericInput("volume", t("Volume")+(uomName ? " ("+uomName+")" : ""), volumePrecision, "number")}
              </div>
            </div>              
            <div className="row mt-3">
              <div className="col-sm-6" style={{visibility: allowFillOrKill ? "visible":"hidden"}}>
                {this.renderSwitch("fillOrKill", t("FillOrKill"))}
              </div>
              <div className="col-sm-6" style={{visibility: allowAllOrNone ? "visible":"hidden"}}>
                {this.renderSwitch("allOrNone", t("AllOrNone"))}
              </div>
            </div>   
            <div className="row mt-3">
            <div className="col-sm-6" style={{ textAlign: "center"}}>
                <button disabled={this.validate() && !this.isLoading} onMouseEnter={() => this.setOrderDirection("Buy")} className="btn btn-primary btn-lg">
                  {t("Buy")}
                  <br/>
                  <h6>{volumeString&&priceString&&(volumeString+" @ "+priceString)}</h6>
                </button>
              </div>
              <div className="col-sm-6" style={{ textAlign: "center"}}>
                <button disabled={this.validate()&& !this.isLoading} onMouseEnter={() => this.setOrderDirection("Sell")} className="btn btn-danger btn-lg">
                  {t("Sell")}
                  <br/>
                  <h6>{volumeString&&priceString&&(volumeString+" @ "+priceString)}</h6>
                </button>
              </div>            
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default withTranslation(["trading"])(OrderForm);
