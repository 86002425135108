import React, { Component } from 'react';
import FontAwesome from "react-fontawesome";
import Table from '../../common/components/table/table';
import { withTranslation } from 'react-i18next';

class SettlementsTable extends Component {
   
    columns =[
        {path: 'termName', label: this.props.t("TermName")},
        {path: 'current', label: this.props.t("Current"), className: "right-align"},
        {path: 'yesterdayDelta', label: this.props.t("YesterdayDelta"), content: data => <span style={{color: (data.current >= data.yesterday) ? "green" : "red"}}><FontAwesome name={(data.current >= data.yesterday) ? "sort-asc" : "sort-desc"}/> {data.yesterdayDelta}</span>, className: "right-align"},
        {path: 'mtdAverage', label: this.props.t("MtdAverage"), className: "right-align"},
        {path: 'ytdAverage', label: this.props.t("YtdAverage"), className: "right-align"},
        {path: 'ytdLow', label: this.props.t("YtdLow"), className: "right-align"},
        {path: 'ytdHigh', label: this.props.t("YtdHigh"), className: "right-align"}
    ];


    mapToViewModel(settlements) {

        const {pricePrecision, currencySymbol} = this.props;

        return settlements.map(settlement => (
            {
                termName: settlement.termName,
                current: currencySymbol + settlement.current.toFixed(pricePrecision),
                yesterday: currencySymbol + settlement.yesterday.toFixed(pricePrecision),
                yesterdayDelta: currencySymbol + (settlement.current-settlement.yesterday).toFixed(pricePrecision),
                mtdAverage: currencySymbol + settlement.mtdAverage.toFixed(pricePrecision),
                ytdAverage: currencySymbol + settlement.ytdAverage.toFixed(pricePrecision),
                ytdLow: currencySymbol + settlement.ytdLow.toFixed(pricePrecision),
                ytdHigh: currencySymbol + settlement.ytdHigh.toFixed(pricePrecision),
            }
        ));
    }

    render() { 
        const {settlements, onSort, sortColumn} = this.props;

        const tableData = this.mapToViewModel(settlements);

        return (
            <Table className="table-extra-small" columns= {this.columns} sortColumn = {'termName'} /*onSort={onSort} */ data={tableData} /* onRowClick={onRowClick} */ valueProperty="termName"/>
        );
    }
}

export default withTranslation(["marketdata"])(SettlementsTable);