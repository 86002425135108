import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API+"ngxSettlement/";

export async function getLatestSettlementSubmissionTime(externalDataSourceId, marketDate) {
  const date = await http.get(apiEndpoint+"latestsettlementsubmissiontime/"+externalDataSourceId+"/"+marketDate);
  return date;
}

export async function processData(request) {  
  const {response: Success} = await http.post(apiEndpoint, request);
  return Success;
}

export async function getSummaryReport(marketId, marketDate) {
  const {data: settleReport} = await http.get(apiEndpoint+"GetSummaryReport/"+marketId+"/"+marketDate);
  return settleReport;
}

export async function getCalSettlementsByMarketDateRange(marketId, fromMarketDate, toMarketDate) {
  const {data: settleReport} = await http.get(apiEndpoint+"GetCalSettlementsByMarketDateRange/"+marketId+"/"+fromMarketDate+"/"+toMarketDate);
  return settleReport;
}