import React, { Component } from "react";
import Table from "../../common/components/table/table";
import Loading from "../../common/components/loading/loading";
import ReviewMarksToggle from "./reviewMarksToggle";
import { getProductWithQualityAttributes } from "../../common/services/products/productService";
import { getMarketWithAttributes } from "../../common/services/markets/marketService";
import { withTranslation } from "react-i18next";
import FontAwesome from "react-fontawesome";

class ReviewMarksTable extends Component {
  state = {
    mappedMarks: [],
    market: {},
    isLoading: true,
  };

  async componentDidMount() {
    const { marks } = this.props;

    const productId = marks[0].productId;
    const marketId = marks[0].marketId;
    const product = await getProductWithQualityAttributes(productId);
    const market = await getMarketWithAttributes(marketId);

    const mappedMarks = market && this.mapToViewModel(marks, market);

    this.setState({
      mappedMarks: mappedMarks,
      market: market,
      columns: this.mapToColumns(product),
      isLoading: false,
    });
  }

  async componentDidUpdate(previousProps) {
    if (previousProps.marks !== this.props.marks) {
      const { marks } = this.props;
      const { market } = this.state;
      const mappedMarks = this.mapToViewModel(marks, market);
      this.setState({ mappedMarks: mappedMarks });
    }
  }

  mapToViewModel(marks, market) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
    };

    const dateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return marks.map((mark) => {
      const marketAttributeIndex =
        market.marketAttributes &&
        market.marketAttributes.findIndex(
          (u) => u.priceBasisId === mark.priceBasisId
        );
      const currencySymbol =
        (marketAttributeIndex > -1 &&
          market.marketAttributes[marketAttributeIndex].currencySymbol) ??
        "$";
      const pricePrecision =
        (marketAttributeIndex > -1 &&
          market.marketAttributes[marketAttributeIndex].pricePrecision) ??
        "2";

      return {
        markId: mark.markId,
        organizationName: mark.organizationName,
        marketDate: new Date(mark.marketDate).toLocaleDateString(
          "en-US",
          dateFormatOptions
        ),
        priceBasisName: mark.priceBasisName,
        termName: mark.termName,
        price:
          mark.price &&
          currencySymbol + Number(mark.price).toFixed(pricePrecision),
        bid:
          mark.bid && currencySymbol + Number(mark.bid).toFixed(pricePrecision),
        offer:
          mark.offer &&
          currencySymbol + Number(mark.offer).toFixed(pricePrecision),
        open:
          mark.open &&
          currencySymbol + Number(mark.open).toFixed(pricePrecision),
        high:
          mark.high &&
          currencySymbol + Number(mark.high).toFixed(pricePrecision),
        low:
          mark.low && currencySymbol + Number(mark.low).toFixed(pricePrecision),
        close:
          mark.close &&
          currencySymbol + Number(mark.close).toFixed(pricePrecision),
        override: mark.override,
        updatedDate: new Date(mark.updatedDate).toLocaleDateString(
          "en-US",
          dateTimeFormatOptions
        ),
        markState: mark.markState,
        ...mark.qualityAttributeValues.reduce((acc, attribute) => {
          acc[attribute.qualityAttributeId] = attribute.value;
          return acc;
        }, {}),
      };
    });
  }

  mapToColumns(product) {
    var columns = [
      {
        path: "organizationName",
        label: this.props.t("SubmittingOrganizationName"),
      },
      ...product.qualityAttributes
        .filter((qualityAttribute) => qualityAttribute.isPriceDriver !== false) //Exclude PriceDrivers
        .map(
          (
            qualityAttribute //Add one new column for each qualityAttribute
          ) => ({
            path: qualityAttribute.qualityAttributeId,
            label: qualityAttribute.qualityAttributeName,
          })
        ),
      { path: "marketDate", label: this.props.t("MarketDate") },
      { path: "termName", label: this.props.t("Term") },
      { path: "priceBasisName", label: this.props.t("PriceBasis") },
      { path: "price", label: this.props.t("Price") },
      { path: "bid", label: this.props.t("Bid") },
      { path: "offer", label: this.props.t("Offer") },
      { path: "open", label: this.props.t("Open") },
      { path: "high", label: this.props.t("High") },
      { path: "low", label: this.props.t("Low") },
      { path: "close", label: this.props.t("Close") },
      {
        path: "override",
        label: this.props.t("Override"),
        content: (data) => <FontAwesome name={data.override ? "check" : ""} />,
      },
      { path: "updatedDate", label: this.props.t("UpdateDate") },
      {
        path: "markState",
        content: (data) => (
          <ReviewMarksToggle
            markId={data.markId}
            markState={data.markState}
            handleApprove={this.props.handleApprove}
            handleReject={this.props.handleReject}
            canApproveMarks={this.props.canApproveMarks}
          />
        ),
      },
    ];

    return columns;
  }

  render() {
    const { mappedMarks, columns, isLoading } = this.state;
    if (isLoading) return <Loading />;
    const {  onSort, sortColumn } = this.props;

    return (
      <div>
        <Table
          className="table-bold"
          columns={columns}
          sortColumn={sortColumn}
          onSort={onSort}
          data={mappedMarks}
          valueProperty="markId"
        />
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(ReviewMarksTable);
