import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import MarkInput from "./markInput";
import { withTranslation } from "react-i18next";

class MarkCell extends Component {
  state = {
    showModal: false, // Set the initial state to show the modal
    price: null,
    bid: null,
    offer: null,
  };

  async componentDidMount() {
    const { mark } = this.props;
    this.setState({ price: mark.price, bid: mark.bid, offer: mark.offer });
  }

  async componentDidUpdate(previousProps) {
    if (
      previousProps.mark.price !== this.props.mark.price ||
      previousProps.mark.bid !== this.props.mark.bid ||
      previousProps.mark.offer !== this.props.mark.offer
    ) {
      const { mark } = this.props;
      this.setState({ price: mark.price, bid: mark.bid, offer: mark.offer });
    }
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleOpen = () => {
    this.setState({ showModal: true });
  };

  handleUpdate = (mark) => {
    this.setState({ showModal: false });
    this.props.submitCallbackFunction(mark);
  };

  render() {
    const { showModal, price, bid, offer } = this.state;

    const { t, allowEdit, allowOverrideMarks, isNew } = this.props;

    const {
      organizationId,
      marketId,
      termId,
      termName,
      shortName,
      priceBasisId,
      currencyId,
      uomId,
      marketDate,
      qualityAttributeValues,
      mark,
      pricePrecision,
      currencySymbol,
    } = this.props;

    //Show the price if populated, bid/offer if not, and nothing if neither of those are popualted.
    const cellValue = price
      ? currencySymbol + Number(price).toFixed(pricePrecision)
      : (bid ? currencySymbol + Number(bid).toFixed(pricePrecision) : "") +
        (bid || offer ? "/" : "-") +
        (offer ? currencySymbol + Number(offer).toFixed(pricePrecision) : "");

    return (
      <div>
        <div
          onClick={this.handleOpen}
          style={{
            color:
              mark.markState === "Approved"
                ? "Green"
                : mark.markState === "Rejected"
                ? "Red"
                : "White",
          }}
          className="mark-cell clickable"
        >
          {cellValue}
        </div>
        <Modal
          show={showModal}
          onHide={this.handleClose}
          //centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            //className="bg-dark text-light"
            
            closeButton
          >
            <Modal.Title>
              {isNew ? (
                <p>{t("AddMark")}</p>
              ) : allowEdit ? (
                <p>{t("UpdateMark")}</p>
              ) : (
                <p>{t("ViewMark")}</p>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body /*className="bg-dark text-light"*/>
            <MarkInput
              shortName={shortName}
              termName={termName}
              organizationId={organizationId}
              marketId={marketId}
              termId={termId}
              priceBasisId={priceBasisId}
              currencyId={currencyId}
              uomId={uomId}
              marketDate={marketDate}
              qualityAttributeValues={qualityAttributeValues}
              mark={mark}
              allowEdit={allowEdit}
              allowOverrideMarks={allowOverrideMarks}
              onSave={this.handleClose}
              isNew={isNew}
              pricePrecision={pricePrecision}
              submitCallbackFunction={this.handleUpdate}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(MarkCell);
