import React, { useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';

const HourlyElectricityPricingAndLoadGraph = ({ data }) => {
    const svgRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

   // Calculate dimensions based on the size of the parent container
   const parentContainer = svg.node().parentNode;
   const parentWidth = parentContainer.clientWidth;
   const parentHeight = parentContainer.clientHeight;
   const margin = { top: 20, right: 60, bottom: 45, left: 50 };

   // Set dimensions considering margins
   const innerWidth = parentWidth - margin.left - margin.right;
   const innerHeight = parentHeight - margin.top - margin.bottom;

    // Parse date format for `forecastTransactionDate` in UTC to avoid time zone issues
    const parseDate = d3.utcParse("%Y-%m-%dT%H:%M:%SZ");

    // Format data, converting dates and calculating daily averages
     const formattedData = data
      .filter(d => d.beginDateTimeMpt && !(d.poolPrice === null && d.albertaInternalLoad === null)) // Ensure valid data
      .map(d => ({
        beginDateTimeMpt: parseDate(d.beginDateTimeMpt),
        poolPrice: d.poolPrice,
        albertaInternalLoad: d.albertaInternalLoad
      })); 

    // Create SVG
    const chartSvg = svg
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `0 0 ${parentWidth} ${parentHeight}`)
        .attr('preserveAspectRatio', 'none') // Preserve aspect ratio
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);    

    // Set up scales
    const xScale = d3.scaleTime()
      .domain(d3.extent(formattedData, d => d.beginDateTimeMpt))
      .range([0, innerWidth]);

    const yScale = d3.scaleLinear()
      .domain([
        0,
        d3.max([...formattedData.map(d => d.poolPrice)]),
      ])
      .nice()
      .range([innerHeight, 0]);

      const yScaleSecondary = d3.scaleLinear()
      .domain([
        d3.min([...formattedData.map(d => d.albertaInternalLoad)]),
        d3.max([...formattedData.map(d => d.albertaInternalLoad)]),
      ])
      .nice()
      .range([innerHeight, 0]);


    // X and Y Axes
    chartSvg.append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale));

    // Format for currency with 2 decimal places
    const formatCurrency = d3.format("$,.2f");

    // Primary Y Axis (Price Delta)
    chartSvg.append("g")
      .call(d3.axisLeft(yScale)
        .tickFormat(formatCurrency) // Apply the currency format here
      );

    chartSvg.append("g")
      .attr("transform", `translate(${innerWidth},0)`)
      .call(d3.axisRight(yScaleSecondary));

/*     // Y Axis label
    chartSvg.append("text")
    //.attr("transform", "rotate(-90)")
    .attr("x", -innerHeight / 2)
    .attr("y", -margin.left + 10)
    .attr("text-anchor", "middle")
    .attr("font-size", "14px")
    .attr("fill", "grey")
    .text("$"); */

    // Secondary Y Axis label
    chartSvg.append("text")
    .attr("transform", "rotate(90)")
    .attr("x", innerHeight / 2)
    .attr("y", -(innerWidth+margin.right-10))
    .attr("text-anchor", "middle")
    .attr("font-size", "14px")
    .attr("fill", "grey")
    .text("Load (MW)");    

    // Add gridlines
    chartSvg.append("g")
    .selectAll(".grid")
    .data(yScale.ticks())
    .enter()
    .append("line")
    .attr("class", "grid")
    .attr("x1", 0)
    .attr("x2", innerWidth)
    .attr("y1", d => yScale(d))
    .attr("y2", d => yScale(d))
    .attr("stroke", "grey")
    .attr("opacity","25%")
    .attr("stroke-width", 1)
    //.attr("stroke-dasharray", "3 3")
    ;

    // Line for Pool Price (dotted blue)
    const linePoolPrice = d3.line()
      .x(d => xScale(d.beginDateTimeMpt))
      .y(d => yScale(d.poolPrice));

    chartSvg.append("path")
      .datum(formattedData)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("d", linePoolPrice);

    // Line for Internal Load (solid red)
    const lineInternalLoad = d3.line()
      .x(d => xScale(d.beginDateTimeMpt))
      .y(d => yScaleSecondary(d.albertaInternalLoad));

    chartSvg.append("path")
      .datum(formattedData)
      .attr("fill", "none")
      .attr("stroke", "#0096FF")      
      .attr("stroke-width", 2)
      .attr("d", lineInternalLoad);

    // Legend
    const legend = chartSvg.append("g")
    .attr("transform", `translate(0, -20)`); // Position legend above the bars

    // Legend: Most Likely
    legend.append("line")
    .attr("x1", innerWidth / 4 -60)  // Starting point of the line
    .attr("y1", 5)  // Position the line vertically
    .attr("x2", innerWidth / 4 + -30) // Ending point of the line (horizontal length)
    .attr("y2", 5)  // Same vertical position as the start
    .attr("stroke", "red") // Blue color
    .attr("stroke-width", 2)  // Line width

    legend.append("text")
    .attr("x", innerWidth / 4 - 25)
    .attr("y", 10)
    .attr("font-size", "12px")
    .attr("fill", "grey")
    .text("Price");

    // Legend: Avg/Day
    legend.append("line")
    .attr("x1", innerWidth / 4 + 30)  // Starting point of the line
    .attr("y1", 5)  // Position the line vertically
    .attr("x2", innerWidth / 4 + 60) // Ending point of the line (horizontal length)
    .attr("y2", 5)  // Same vertical position as the start
    .attr("stroke", "#0096FF") // Blue color
    .attr("stroke-width", 2);  // Line width

    legend.append("text")
    .attr("x", innerWidth / 4 + 65)
    .attr("y", 10)
    .attr("font-size", "12px")
    .attr("fill", "grey")
    .text("Load");

  }, [data]);

  useEffect(() => {
    renderChart();

    return () => {
/*         // Remove the tooltip when the component unmounts
        if (tooltipRef.current) {
            tooltipRef.current.remove();
        } */
    };   
}, [renderChart]);

useEffect(() => {
    const innerChartRef = svgRef;
    
    const handleResize = () => {
        renderChart(); // Re-render the chart on resize
    };

    const observer = new ResizeObserver(handleResize);
    if (innerChartRef.current) {
        observer.observe(innerChartRef.current);
    }

    // Clean up observer on component unmount
    return () => {
        if (innerChartRef.current) {
            observer.unobserve(innerChartRef.current);
        }
    };
}, [renderChart]);
  
  return (
    <svg ref={svgRef} width="100%" height="100%"></svg>
  );
};

export default HourlyElectricityPricingAndLoadGraph;
