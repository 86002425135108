import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import QualityAttributeBadges from "../common/qualityAttributeBadges";
import Form from "./../../common/components/form/form";
import { withTranslation } from "react-i18next";

class OrderModifyForm extends Form {
  state = {
    data: {
      orderDirection: "",
      orderId: "",
      instrumentId: "",
      price: 0,
      volume: 0,
      eventId: "",
    },
    errors: {},
  };

  schema = {
    orderId: Joi.string().required(),
    instrumentId: Joi.string().required(),
    orderDirection: Joi.string(),
    price: Joi.number().required().label(this.props.t("Price")),
    volume: Joi.number().required().label(this.props.t("Volume")),
    eventId: Joi.string(), 
  };

  async componentDidMount() {
    const {orderData} = this.props;
      this.setState ({data: this.mapToViewModel(orderData)})
  }

  mapToViewModel(orderData) {
    return {
      orderDirection: orderData.orderDirection,
      orderId: orderData.orderId,
      instrumentId: orderData.instrumentId,
      price: orderData.price,
      volume: orderData.volume,
      eventId: uuid(),
    };
  }

  doSubmit = async () => {
    const { modifyOrder, handleModalClose } = this.props;
    const orderData = {...this.state.data};

    orderData.price = Number(orderData.price);
    orderData.volume = Number(orderData.volume);

    modifyOrder(orderData);
    handleModalClose();
  };

  render() {
    const
    { 
      t, 
      orderData,
      currencySymbol,
      pricePrecision,
      volumePrecision,
      currencyName,
      uomName,
      marketName,
      priceBasisName,
      termName,
      qualityAttributeValues,
    } = this.props;

    const {
      orderDirection,
    } = orderData;

    const priceString = this.state.data.price && (currencySymbol??"$")+ Number(this.state.data.price).toFixed(pricePrecision ?? 2);
    const volumeString =  this.state.data.volume && Number(this.state.data.volume).toFixed(volumePrecision ?? 0);


    return (
      <div className="container-fluid">
        <form
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={false} >      
            <div className="row">
              <div className="col-sm-6">
                <h6>{t("MarketName")+":"}</h6>
              </div>
              <div className="col-sm-6">
                {marketName}
              </div>
            </div>    
            <div className="row">
              <div className="col-sm-6">
                <h6>{t("PriceBasis")+":"}</h6>
              </div>
              <div className="col-sm-6">
                {priceBasisName}
              </div>
            </div>    
            <div className="row">
              <div className="col-sm-6">
                <h6>{t("TermName")+":"}</h6>
              </div>
              <div className="col-sm-6">
                {termName}
              </div>
            </div>    
            <div className="row">
              <div className="col-sm-6">
                <h6>{t("QualityAttributes")+":"}</h6>
              </div>
              <div className="col-sm-6">
                <QualityAttributeBadges qualityAttributeValues={qualityAttributeValues} />
              </div>
            </div>                                                
            <div className="row mt-3"></div>                                                           
            <div className="row">
              <div className="col-sm-6">
                {this.renderNumericInput("price", t("Price")+(currencyName ? " ("+currencyName+")" : ""), pricePrecision, "number")}
              </div>
              <div className="col-sm-6">
                {this.renderNumericInput("volume", t("Volume")+(uomName ? " ("+uomName+")" : ""), volumePrecision, "number")}
              </div>
            </div>              
            <div className="row mt-3">
            <div className="col-sm-12" style={{ textAlign: "center"}}>
                <button disabled={this.validate() && !this.isLoading} className={"btn btn-lg "+(orderDirection === "Buy" ? "btn-primary" : "btn-danger")}>
                  {t(orderDirection)}
                  <br/>
                  <h6>{volumeString&&priceString&&(volumeString+" @ "+priceString)}</h6>
                </button>
              </div>       
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default withTranslation(["trading"])(OrderModifyForm);
