import React from "react";
import { usePermitted } from "../../common/components/permissions/permissions";
import { useKeycloak } from "@react-keycloak/web";
import SubmitExternalData from "./submitExternalData";

const SubmitExternalDataAuthWrapper = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const allowEdit = usePermitted(["api-manage-external-data"]);
  const bypassSubscriptions = usePermitted(["operator","administrator"]);
  const userAccountId =
    initialized && keycloak.tokenParsed.OriginatingUserAccountID;
  const userOrganizations = initialized && keycloak.tokenParsed.group;

  return (
    <SubmitExternalData
      allowEdit={allowEdit}
      userAccountId={userAccountId}
      userOrganizations={userOrganizations}
      marketsWithAttributes={props.marketsWithAttributes}
      marketGroupMarkets={props.marketGroupMarkets}
      products={props.products}
      venues={props.venues}
      termSchedules={props.termSchedules}
      bypassSubscriptions={bypassSubscriptions}
      userAccountSubscriptionsByUserAccount={
        props.userAccountSubscriptionsByUserAccount
      }
    />
  );
};

export default SubmitExternalDataAuthWrapper;
