import React, { Component } from 'react';
import _ from 'lodash';
import Table from '../../common/components/table/table';
import Loading from '../../common/components/loading/loading';
import { pivotQualityAttributes} from "../../common/services/products/productUtilities";
import { getTermsByDate} from "../../common/services/markets/termService";
import { joinArrays } from "../../common/services/utilities";
import MarkCell from "./markCell";
import { SHA256 } from 'crypto-js';
import { withTranslation } from 'react-i18next';
import LineChart from '../../common/components/d3/lineChart';
import uuid from 'react-uuid';
import {aggregateMarksWithoutDate} from "../../common/services/marketdata/marketDataUtilities";

class MarksForwardTable extends Component {

    constructor(props) {
        super(props);
    
        this.rowHover = this.rowHover.bind(this);
        this.rowExit = this.rowExit.bind(this);
    }


    state = {
        marks: [],
        qualityAttributeValueHash: null,
        lineChartMarksRaw: [],
        startDate: null,
        endDate: null,
        yMax: null,
        yMin: null,        
        columns: [],
        pageSize: 20,
        currentPage: 1,
        sortColumn: {path: 'id', order: 'desc'},
        isLoading: true,
   };

    async componentDidMount() {
        await this.initializeTable(this.props);
    };

    async componentDidUpdate(previousProps) {
        if
        (
            previousProps.marketDate !== this.props.marketDate ||
            previousProps.priceBasisId !== this.props.priceBasisId  ||
            previousProps.currencyId !== this.props.currencyId  ||
            previousProps.uomId !== this.props.uomId ||
            previousProps.organizaitonId !== this.props.organizaitonId ||
            !_.isEqual(previousProps.marks, this.props.marks)   
        )
        {            
            await this.initializeTable(this.props);
        }
    };

    rowHover(data)
    {        
        this.setState({qualityAttributeValueHash: data.qualityAttributeValueHash})
    }

    rowExit(data)
    {
        this.setState({qualityAttributeValueHash: null})
    }

    async initializeTable(passedProps)
    {
        const {marks, product, marketDate, termScheduleId } = passedProps;

        const terms = await getTermsByDate(termScheduleId, marketDate);

        const lineChartMarksRaw = this.joinMarksToTerms(marks, terms)

        const aggregatedMarks= aggregateMarksWithoutDate(marks);

        const columns = this.mapToColumns(product, terms)                
        const pivotedData = pivotQualityAttributes(product);

        const joinedData = this.joinMarks(pivotedData, aggregatedMarks)

        const startDate = terms.length > 0 ? new Date(terms.reduce((minDate, currentItem) => {
            return currentItem.startDate < minDate ? currentItem.startDate : minDate;
        }, terms[0].startDate)) : new Date();
        
        const endDate = terms.length > 0 ? new Date(terms.reduce((maxDate, currentItem) => {
            return currentItem.endDate > maxDate ? currentItem.endDate : maxDate;
        }, terms[0].endDate)) : new Date();

        this.setState({marks: joinedData, lineChartMarksRaw: lineChartMarksRaw, startDate: startDate, endDate: endDate, columns: columns, isLoading:false});

    };

    joinMarksToTerms(marks, terms)
    {
        const joinedMarks = joinArrays(marks,terms,'termId');

        const expandedMarks = [];

        joinedMarks.forEach(mark => {
            //const startDate = new Date(mark.startDate).toLocaleString('en-US', { timeZone: 'UTC' });
            //const endDate = new Date(mark.endDate).toLocaleString('en-US', { timeZone: 'UTC' });
        
            let currentDate = (new Date(mark.startDate));

            while (currentDate <= new Date(mark.endDate)) {
              // Create a new datapoint for each month
              const newDatapoint = {
                ...mark,
                //price: mark.price ?? (mark.bid && mark.offer && ((mark.bid+mark.offer)/2)), //proxy in midpoints when available
                price: mark.price, //No longer a need to proxy in midpoints,these are handled in the aggregation now.
                // startDate: new Date(currentDate),
                // endDate: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
                date: currentDate.toISOString(),
                // Add other properties from the original datapoint as needed
              };
        
              expandedMarks.push(newDatapoint);
        
              // Move to the next month
              currentDate.setMonth(currentDate.getMonth() + 1);
              currentDate.setDate(1); // Reset to the first day of the month to ensure accurate iteration
            }
          });

        return expandedMarks
    }

    mapToColumns(products, terms)
    {
        const
        {
            organizationId,
            marketId,            
            marketDate,
            priceBasisId,
            currencyId,
            uomId,
            pricePrecision,
            volumePrecision,
            currencySymbol  
        } = this.props;

        var columns = [
        {path: 'shortName', label: this.props.t('MarketDataProduct')}, //Set the product name as the first column
        ...products.qualityAttributes
        .filter(qualityAttribute => qualityAttribute.isPriceDriver !== false) //Exclude PriceDrivers
        .map(qualityAttribute => ( //Add one new column for each qualityAttribute
            {
                path: qualityAttribute.qualityAttributeId,
                label: qualityAttribute.qualityAttributeName,
            }
        )),
        ...terms
        .sort((a, b) => new Date(a.endDate) > new Date(b.endDate) ? 1 : -1)
        .map(term =>( //Add one new column for each term
            {
                path: term.termId,
                label: term.name,
                //className: "clickable",            
                content: data => <MarkCell                               
                    termId={term.termId} 
                    organizationId={organizationId}
                    marketId={marketId}
                    priceBasisId={priceBasisId}
                    currencyId={currencyId}
                    uomId={uomId}
                    pricePrecision={pricePrecision}
                    volumePrecision={volumePrecision}
                    currencySymbol={currencySymbol}                    
                    marketDate={marketDate}
                    mark={data[term.termId] ?? {markId: uuid(), eventId: uuid(), active: true}} 
                    qualityAttributeValues ={data.qualityAttributeValues} 
                    shortName={data["shortName"]}
                    isNew={data[term.termId] ? false: true}
                />
            }
        ))]

        return columns;
    }

    joinMarks(pivotedData, marks)
    {
        const dataWithHashes = pivotedData.map(obj =>
        {
            // Extract and sort the values of the QualityAttributeValueId field
            const sortedQualityAttributeValues = obj.qualityAttributeValues
                .map(qualityAttributeValue => qualityAttributeValue.qualityAttributeValueId)  // Extract the values
                .filter(value => value !== undefined)  // Filter out undefined values
                .sort();  // Sort the values

                // Concatenate the sorted values
                const concatenatedValues = sortedQualityAttributeValues.join('');
                const qualityAttributeValueHash = SHA256(concatenatedValues).toString().toUpperCase();

                return {...obj, qualityAttributeValueHash}
        });

        

        //Pivot the marks
        const groupedByQualityAttributeValueHash = marks.reduce((result, obj) => {
            const { qualityAttributeValueHash, termId, ...rest } = obj;
        
            if (!result[qualityAttributeValueHash]) {
            result[qualityAttributeValueHash] = {};
            }
        
            result[qualityAttributeValueHash][termId] = { ...rest };
        
            return result;
        }, {});
        
        const pivotedMarks = Object.entries(groupedByQualityAttributeValueHash).map(([qualityAttributeValueHash, terms]) => ({
            qualityAttributeValueHash,
            ...terms
        }));

        const joinedData = joinArrays(dataWithHashes,pivotedMarks,'qualityAttributeValueHash');
        
        return joinedData
    }

    getLineChartMarks()
    {
        const {lineChartMarksRaw, qualityAttributeValueHash} = this.state;        

        const lineChartMarks = lineChartMarksRaw.filter(x => x.qualityAttributeValueHash === (qualityAttributeValueHash ?? x.qualityAttributeValueHash));

        const yMin = lineChartMarksRaw.reduce((minValue, currentItem) => {
            return Math.min(minValue, currentItem.price ?? currentItem.bid ?? currentItem.offer);
          }, Number.POSITIVE_INFINITY);


        const yMax = lineChartMarksRaw.reduce((maxValue, currentItem) => {
            return Math.max(maxValue, currentItem.price ?? currentItem.offer ?? currentItem.bid);
          }, Number.NEGATIVE_INFINITY);

         return {
            lineChartMarks: lineChartMarks,
            yMax: yMax > 0  ? yMax * 1.05 : yMax * 0.95, //scale to within 20% for aesthetic reasons
            yMin: yMin > 0 ? yMin * 0.95 : yMin * 1.05 //scale to within 20% for aesthetic reasons
        };
    }

    render() { 
        const {sortColumn, isLoading, columns, marks,startDate, endDate} = this.state;

        const {yMin, yMax, lineChartMarks } = this.getLineChartMarks();

        if (isLoading) return (<Loading/>);

        const { forwardReportingPeriodicity, currencySymbol, pricePrecision} = this.props;

        return (
            <div className="container-fluid">
                <div className="row market-data-graph">
                    <LineChart data={lineChartMarks} forwardReportingPeriodicity={forwardReportingPeriodicity} startDate={startDate} endDate={endDate} yMin={yMin} yMax={yMax} currencySymbol={currencySymbol} pricePrecision={pricePrecision}/>
                </div>
                <div className="row marks-table" >
                    <Table className="table-condensed clickable" columns={columns} sortColumn = {sortColumn} onMouseEnter={this.rowHover} onMouseLeave={this.rowExit} data={marks} valueProperty="qualityAttributeValueHash"/>
                </div>                                
            </div>

        );
    }
}

export default withTranslation(["marketdata"])(MarksForwardTable)