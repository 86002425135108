import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getMarketGroupHistory } from "../../../common/services/markets/marketGroupService";
import { withTranslation } from "react-i18next";

class MarketGroupHistory extends Component {
  state = {
    marketGroupHistory: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { marketGroupId } = this.props;
    const marketGroupHistory = this.mapToViewModel(
      await getMarketGroupHistory(marketGroupId)
    );

    this.setState({
      marketGroupHistory: _.orderBy(marketGroupHistory, "id", "desc"),
      isLoading: false,
    });
  }

  mapToViewModel(marketGroups) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return marketGroups.map((marketGroup) => ({
      id: marketGroup.id,
      name: marketGroup.name,
      active: marketGroup.active.toString(),
      updatedByUserAccountId: marketGroup.createdByUserAccountId,
      updatedBy:
        marketGroup.updatedByUserAccountFirstName +
        " " +
        marketGroup.updatedByUserAccountLastName,
      updatedDate: new Date(marketGroup.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      writeDate: new Date(marketGroup.writeDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      eventId: marketGroup.eventId,
    }));
  }

  columns = [
    { path: "id", label: this.props.t("RecordID") },
    { path: "name", label: this.props.t("MarketGroupName") },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { marketGroupHistory, sortColumn, isLoading } = this.state;

    if (isLoading) return <Loading />;

    const { t, marketGroupId } = this.props;

    return (
      <div className="history-table-container">
        {t("MarketGroupID")} {marketGroupId}
        <Table
          className="table-extra-small"
          columns={this.columns}
          sortColumn={sortColumn}
          data={marketGroupHistory}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(MarketGroupHistory);
