import React, { Component } from 'react';
import _ from 'lodash';
import Table from '../../../common/components/table/table';
import Loading from '../../../common/components/loading/loading';
import { getUserAccountHistory} from "../../../common/services/auth/userAccountService";
import { withTranslation } from 'react-i18next';

class UserAccountHistory extends Component {

    state = {
        userAccountHistory: [],
        pageSize: 20,
        currentPage: 1,
        sortColumn: {path: 'id', order: 'desc'},
        isLoading: true
   };

    async componentDidMount() {
        const {userAccountId} = this.props;
        const userAccountHistory = this.mapToViewModel(await getUserAccountHistory(userAccountId));
        this.setState({userAccountHistory: _.orderBy(userAccountHistory, "id", "desc"), isLoading:false});
    };

    mapToViewModel(userAccounts) {
        const dateFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
            hour: 'numeric',
            hour12: false,
            minute: 'numeric',
            second: 'numeric'
        }

        return userAccounts.map(userAccount => (
            {
                id: userAccount.id,
                userAccountId: userAccount.userAccountId,
                login: userAccount.login,
                firstName: userAccount.firstName,
                lastName: userAccount.lastName,
                email: userAccount.email,
                active: userAccount.active.toString(),
                updatedByUserAccountId: userAccount.updatedByUserAccountId,
                updatedBy: userAccount.updatedByUserAccountFirstName+" "+userAccount.updatedByUserAccountLastName,
                updatedDate: new Date(userAccount.updatedDate).toLocaleDateString('en-US', dateFormatOptions),
                writeDate: new Date(userAccount.writeDate).toLocaleDateString('en-US', dateFormatOptions),
                eventId: userAccount.eventId
            }
        ));
    }

    columns =[
        {path: 'id', label: this.props.t("RecordID")},
        {path: 'login', label: this.props.t("Login")},
        {path: 'firstName', label: this.props.t("FirstName")},
        {path: 'lastName', label: this.props.t("LastName")},
        {path: 'email', label: this.props.t("Email")},
        {path: 'active', label: this.props.t("Active")},
        {path: 'updatedBy', label: this.props.t("UpdatedBy")},
        {path: 'updatedDate', label: this.props.t("UpdateDate")},
        {path: 'writeDate', label: this.props.t("WriteDate")},
        {path: 'eventId', label: this.props.t("EventID")}
    ];

    render() { 
        const {userAccountHistory, sortColumn, isLoading} = this.state;

        if (isLoading) return (<Loading/>);

        const {t, userAccountId} = this.props;

        return (
            <div className="history-table-container">
                    {t("UserAccountID")} {userAccountId}
                    <Table className="table-extra-small" columns= {this.columns} sortColumn = {sortColumn} data={userAccountHistory} valueProperty="id"/>

            </div>
        );
    }
}

export default withTranslation(["auth"])(UserAccountHistory);