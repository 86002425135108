import React from "react";
import { usePermitted } from "../../common/components/permissions/permissions";
import { useKeycloak } from "@react-keycloak/web";
import Trades from "./submitTrades";

const SubmitTradesAuthWrapper = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const allowEdit = usePermitted(["api-manage-trades"]);
  const allowEditOnBehalf = usePermitted(["api-approve-trades"]);
  const userAccountId =
    initialized && keycloak.tokenParsed.OriginatingUserAccountID;
  const userOrganizations = initialized && keycloak.tokenParsed.group;

  return (
    <Trades
      allowEdit={allowEdit}
      userAccountId={userAccountId}
      userOrganizations={userOrganizations}
      allowEditOnBehalf={allowEditOnBehalf}
      marketsWithAttributes={props.marketsWithAttributes}
      marketGroupMarkets={props.marketGroupMarkets}
      products={props.products}
      venues={props.venues}
      termSchedules={props.termSchedules}
      userAccountSubscriptionsByUserAccount={
        props.userAccountSubscriptionsByUserAccount
      }
    />
  );
};

export default SubmitTradesAuthWrapper;
