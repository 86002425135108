import React, { Component } from 'react';
import FontAwesome from "react-fontawesome";
import Table from '../../../common/components/table/table';
import { withTranslation } from 'react-i18next';

class UserAccountsTable extends Component {
   
    columns =[
        {path: 'login', label: this.props.t("Login")},
        {path: 'firstName', label: this.props.t("FirstName")},
        {path: 'lastName', label: this.props.t("LastName")},
        {path: 'email', label: this.props.t("Email")},
        {path: 'active', label: this.props.t("Active"), content: userAccount => <FontAwesome name={userAccount.active ? "check" : "times"}/>, className:"centered"}
    ];

    render() { 
        const {userAccounts, onSort, sortColumn, onRowClick} = this.props;
        return (
            <Table className="table-bold" columns= {this.columns} sortColumn = {sortColumn} onSort={onSort} data={userAccounts} onRowClick={onRowClick} valueProperty="userAccountId"/>
        );
    }
}

export default withTranslation(["auth"])(UserAccountsTable);